import { useEffect } from "react"
import PropTypes from "prop-types"
import { SeriesCardList } from "source/publishing/sermons/Channel"
import { LimitedInfiniteScroll } from "source/shared/components"
import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useInfiniteQuery } from "@tanstack/react-query"

SeriesLibrary.propTypes = {
  channelId: PropTypes.string.isRequired,
  queryParams: PropTypes.object,
}

export function SeriesLibrary({ channelId, queryParams = {} }) {
  const perPage = 30
  const loadManuallyAfterThisOffset = perPage * 2

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage: hasMore,
    isFetching,
    isFetchingNextPage: loadingMore,
  } = useInfiniteQuery({
    queryKey: ["channels", channelId, "series", queryParams],
    queryFn: ({ pageParam }) => {
      return sessionApiClient.get(
        `/publishing/v2/channels/${channelId}/series`,
        {
          ...queryParams,
          per_page: perPage,
          offset: pageParam,
        },
      )
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      if (allPages.length * perPage < lastPage.meta.total_count) {
        return allPages.length * perPage
      }
    },
  })
  const records = data.pages.flatMap((page) => page.data) || []
  const loadMore = () => !isFetching && fetchNextPage()
  const offset = records.length

  useEffect(() => {
    if (error) {
      console.log(`There was an error fetching series: ${error}`) // eslint-disable-line no-console
    }
  }, [error])

  return (
    <LimitedInfiniteScroll
      loadMoreButtonText="View more"
      keepLoadingOnScroll={offset < loadManuallyAfterThisOffset}
      hasMore={hasMore}
      loadMore={loadMore}
      loadingMore={loadingMore}
    >
      <SeriesCardList series={records} />
    </LimitedInfiniteScroll>
  )
}
