import { sessionApiClient } from "@planningcenter/cc-api-client"
import { useSuspenseQuery } from "@tanstack/react-query"
import { useFlipperFeatureEnabled } from "source/shared/flipperFeatures"

import { SingularAPIResponseWithIncluded } from "source/shared/types"
import {
  ChannelAPIRecord,
  EpisodeAPIRecordWithIncluded,
  EpisodeResourceAPIRecord,
  EpisodeTimesAPIRecord,
} from "../shared/types"

type IncludedTypes =
  | ChannelAPIRecord
  | EpisodeResourceAPIRecord
  | EpisodeTimesAPIRecord
type EpisodeSingularAPIResponseWithIncluded = SingularAPIResponseWithIncluded<
  EpisodeAPIRecordWithIncluded,
  IncludedTypes
>
const useEpisodeQueryKey = (episodeId: string) => {
  const ROLLOUT_episode_speakers = useFlipperFeatureEnabled(
    "ROLLOUT_episode_speakers",
  )

  return [
    "episode",
    episodeId,
    [
      "channel",
      "episode_times",
      "episode_resources",
      "person_episode_progress",
      "series",
      ROLLOUT_episode_speakers && "speakerships.speaker",
    ]
      .filter(Boolean)
      .join(","),
  ]
}

export function useEpisode(episodeId: string) {
  return useSuspenseQuery({
    queryKey: useEpisodeQueryKey(episodeId),
    queryFn: ({ queryKey }) => {
      const [_, episodeId, include] = queryKey
      return sessionApiClient
        .get(`/publishing/v2/episodes/${episodeId}`, { include })
        .catch(
          (json: unknown) => json,
        ) as Promise<EpisodeSingularAPIResponseWithIncluded>
    },
    refetchOnWindowFocus: false,
  })
}
