import { useState, useEffect } from "react"
import { css } from "@emotion/react"
import PropTypes from "prop-types"

import { Icon } from "source/shared/components"
import { shouldOpenInChurchCenterModal, urlForResource } from "../shared/utils"

Resources.propTypes = {
  episodeDescription: PropTypes.string,
  episodeResources: PropTypes.array,
  notesToggleButton: PropTypes.node,
  showFullDescription: PropTypes.bool,
}

export default function Resources({
  episodeDescription,
  episodeResources,
  notesToggleButton,
  showFullDescription = false,
  ...restProps
}) {
  const icons = {
    generic_url: "fa-link",
    giving_fund: "fa-money-bill",
    people_form: "fa-user-plus",
    services_public_page: "fa-list-ul",
  }
  const [descriptionExpanded, setDescriptionExpanded] = useState(
    episodeResources.length === 0,
  )

  const [truncatedEpisodeDescription, setTruncatedEpisodeDescription] =
    useState("")

  useEffect(() => {
    if (!episodeDescription) return
    let truncated = episodeDescription
    // strategy 1: by words
    const words = episodeDescription.split(" ")
    if (words.length > 35) {
      truncated = words.slice(0, 25).join(" ") + "..."
    }
    // strategy 2: by lines
    const lines = truncated.split("\n")
    if (lines.length > 4) {
      truncated = lines.slice(0, 3).join("\n") + "..."
    }
    setTruncatedEpisodeDescription(truncated)
  }, [episodeDescription])

  const shouldShowMoreButton =
    !showFullDescription &&
    episodeDescription !== truncatedEpisodeDescription &&
    episodeResources.length > 0

  return (
    <div {...restProps}>
      {episodeDescription && (
        <div data-name="episode summary" className="action-drawer mb-2">
          <p className="mb-0" css={{ whiteSpace: "pre-line" }}>
            <span>
              {descriptionExpanded || showFullDescription
                ? episodeDescription
                : truncatedEpisodeDescription}
            </span>
            {shouldShowMoreButton && (
              <button
                style={{ display: "block", height: "auto" }}
                className="btn link-btn mt-1"
                onClick={() => setDescriptionExpanded(!descriptionExpanded)}
              >
                {descriptionExpanded ? "Show Less" : "Show More"}
              </button>
            )}
          </p>
        </div>
      )}
      <div className="d-f fd-c g-1">
        {notesToggleButton}
        {episodeResources.map((resource) => (
          <EpisodeResourcesLink
            key={resource.id}
            icon={
              (resource?.attributes?.icon &&
                `fa-${resource.attributes.icon}`) ||
              icons[resource?.attributes?.kind]
            }
            title={resource.attributes.title}
            url={urlForResource(resource)}
          />
        ))}
      </div>
    </div>
  )
}

EpisodeResourcesLink.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
export function EpisodeResourcesLink({
  icon = "fa-link",
  onClick,
  title,
  url,
}) {
  return (
    <a
      className="d-f ai-c jc-sb ta-l c-tint2 fs-3 mb-1 w-100%"
      data-open-in-church-center-modal={shouldOpenInChurchCenterModal(url)}
      css={styles.resourceLink}
      href={url}
      onClick={onClick}
      {...(isAbsoluteUrl(url)
        ? { rel: "noopener noreferrer", target: "_blank" }
        : {})}
    >
      <span className="mr-2">
        {/* icons from general: bill, link, outlined-notes, plus-person  */}
        <Icon symbol={icon} aria-hidden />
      </span>
      <span className="f-1">{title}</span>
      <Icon
        css={styles.resourceChevron}
        symbol="general#right-chevron"
        aria-hidden
      />
    </a>
  )
}

function isAbsoluteUrl(url) {
  return /^(https?:)?\/\//.test(url)
}

const styles = {
  resourceLink: css`
    border: 1px solid var(--color-tint6);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    height: 42px;
    font-weight: 400;

    &:hover {
      background-color: var(--color-tint6);
    }
  `,
  resourceChevron: css`
    width: 12px;
    height: 12px;
    color: var(--color-tint3);
  `,
}
