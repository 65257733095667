import PropTypes from "prop-types"
import { useParams } from "react-router-dom"
import { useApiRead } from "source/shared/SessionApiResource"
import { SeriesLibrary } from "source/publishing/sermons/SeriesLibrary"
import {
  BreadcrumbNavigation,
  BreadcrumbPage,
  BreadcrumbDividerIcon,
} from "source/shared/components"
import useNotifyNativeLoaded from "source/shared/hooks/useNotifyNativeLoaded"
import OpenGraphTags from "source/shared/OpenGraphTags"
import { useQRCode } from "source/shared/QRCode"
import { Heading } from "@planningcenter/doxy-web"
import { Error404 } from "source/shared/components/Error404"

export function SeriesList() {
  const { channelId } = useParams()
  const { data: channel, errors } = useApiRead(
    `/publishing/v2/channels/${channelId}/`,
  )
  const errorStatus = errors?.[0]?.status

  return errorStatus === "404" ? (
    <Error404 />
  ) : (
    <SeriesListWithData channel={channel} />
  )
}

SeriesListWithData.propTypes = {
  channel: PropTypes.object.isRequired,
}
function SeriesListWithData({ channel }) {
  useNotifyNativeLoaded()
  useQRCode()

  return (
    <div className="d-f fd-c g-2">
      <OpenGraphTags title={`${channel.attributes.name} Sermon Series`} />
      <BreadcrumbNavigation>
        <BreadcrumbPage
          linkUrl={`/channels/${channel.id}`}
          pageText={channel.attributes.name}
        />
        <BreadcrumbDividerIcon />
        <BreadcrumbPage pageText="Series" isActive />
      </BreadcrumbNavigation>
      <Heading level={1} text="Series" />
      <SeriesLibrary
        channelId={channel.id}
        queryParams={{
          filter: "published,with_published_episodes",
          order: "-ended_at",
        }}
      />
    </div>
  )
}
